/**
*
*	Name:			DANA Fonts
*	Version:			3.0
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		nov 12, 2021
*	Updated on:		nov 12, 2021
*	Website:			http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونت دانا یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
DANA fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (XE9W0S36)
------------------------------------------------------------------------------------- fonts/-
*
**/

@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 10;
    src: url('../fonts/dana/woff2/Dana-Hairline.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-Hairline.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/dana/woff2/Dana-Thin.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-Thin.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/dana/woff2/Dana-UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-UltraLight.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/dana/woff2/Dana-Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-Light.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/dana/woff2/Dana-Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-Medium.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/dana/woff2/Dana-DemiBold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-DemiBold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/dana/woff2/Dana-ExtraBold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-ExtraBold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/dana/woff2/Dana-Black.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-Black.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 930;
    src: url('../fonts/dana/woff2/Dana-ExtraBlack.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-ExtraBlack.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 960;
    src: url('../fonts/dana/woff2/Dana-Heavy.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-Heavy.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 990;
    src: url('../fonts/dana/woff2/Dana-fat.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-fat.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/dana/woff2/Dana-Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-Bold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/dana/woff2/Dana-Regular.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/dana/woff/Dana-Regular.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}


